@import "swiper.orig.css";
@import "leaflet.orig.css";
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0px;
  padding: 0;
  border: 0;
  outline: 0;
}
body {
  font-size: 110%;
  padding: 0;
  margin: 0;
}
@font-face {
  font-family: 'meb';
  src: url('fonts/Muli-ExtraBold.ttf');
}
@font-face {
  font-family: 'msb';
  src: url('fonts/Muli-SemiBold.ttf');
}
@font-face {
  font-family: 'mb';
  src: url('fonts/Muli-Bold.ttf');
}
@font-face {
  font-family: 'mr';
  src: url('fonts/Muli-Regular.ttf');
}
@font-face {
  font-family: 'ml';
  src: url('fonts/Muli-Light.ttf');
}
@font-face {
  font-family: 'mi';
  src: url('fonts/Muli-Italic.ttf');
}
@font-face {
  font-family: 'or';
  src: url('fonts/OpenSans-Regular.ttf');
}
/* Generated by https://google-webfonts-helper.herokuapp.com/ */
/* mitr-200 - latin-ext_latin */
@font-face {
  font-family: 'Mitr';
  font-style: normal;
  font-weight: 200;
  src: local(''), url('fonts/mitr-v6-latin-ext_latin-200.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */ url('fonts/mitr-v6-latin-ext_latin-200.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* mitr-300 - latin-ext_latin */
@font-face {
  font-family: 'Mitr';
  font-style: normal;
  font-weight: 300;
  src: local(''), url('fonts/mitr-v6-latin-ext_latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */ url('fonts/mitr-v6-latin-ext_latin-300.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* mitr-regular - latin-ext_latin */
@font-face {
  font-family: 'Mitr';
  font-style: normal;
  font-weight: 400;
  src: local(''), url('fonts/mitr-v6-latin-ext_latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */ url('fonts/mitr-v6-latin-ext_latin-regular.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* mitr-500 - latin-ext_latin */
@font-face {
  font-family: 'Mitr';
  font-style: normal;
  font-weight: 500;
  src: local(''), url('fonts/mitr-v6-latin-ext_latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */ url('fonts/mitr-v6-latin-ext_latin-500.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* lato-100 - latin-ext_latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 100;
  src: local(''), url('fonts/lato-v17-latin-ext_latin-100.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */ url('fonts/lato-v17-latin-ext_latin-100.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* lato-100italic - latin-ext_latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 100;
  src: local(''), url('fonts/lato-v17-latin-ext_latin-100italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */ url('fonts/lato-v17-latin-ext_latin-100italic.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* lato-300 - latin-ext_latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: local(''), url('fonts/lato-v17-latin-ext_latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */ url('fonts/lato-v17-latin-ext_latin-300.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* lato-300italic - latin-ext_latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 300;
  src: local(''), url('fonts/lato-v17-latin-ext_latin-300italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */ url('fonts/lato-v17-latin-ext_latin-300italic.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* lato-regular - latin-ext_latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: local(''), url('fonts/lato-v17-latin-ext_latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */ url('fonts/lato-v17-latin-ext_latin-regular.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* lato-italic - latin-ext_latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  src: local(''), url('fonts/lato-v17-latin-ext_latin-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */ url('fonts/lato-v17-latin-ext_latin-italic.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* lato-700 - latin-ext_latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: local(''), url('fonts/lato-v17-latin-ext_latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */ url('fonts/lato-v17-latin-ext_latin-700.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* lato-700italic - latin-ext_latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 700;
  src: local(''), url('fonts/lato-v17-latin-ext_latin-700italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */ url('fonts/lato-v17-latin-ext_latin-700italic.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
.fontsize-p {
  font-size: 0.91rem;
}
@media screen and (max-width: 700px) {
  .fontsize-p {
    font-size: 0.9rem;
  }
}
@media screen and (min-width: 700px) and (max-width: 800px) {
  .fontsize-p {
    font-size: 0.9rem;
  }
}
.fontsize-h1 {
  font-size: 2.2rem;
}
@media screen and (max-width: 700px) {
  .fontsize-h1 {
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 700px) and (max-width: 800px) {
  .fontsize-h1 {
    font-size: 1.5rem;
  }
}
.fontsize-h2 {
  font-size: 1.2rem;
}
@media screen and (max-width: 700px) {
  .fontsize-h2 {
    font-size: 1.3rem;
  }
}
@media screen and (min-width: 700px) and (max-width: 800px) {
  .fontsize-h2 {
    font-size: 1.3rem;
  }
}
.fontsize-h3 {
  font-size: 1rem;
}
@media screen and (max-width: 700px) {
  .fontsize-h3 {
    font-size: 1.1rem;
  }
}
@media screen and (min-width: 700px) and (max-width: 800px) {
  .fontsize-h3 {
    font-size: 1.1rem;
  }
}
.fontsize-h4 {
  font-size: 1rem;
}
@media screen and (max-width: 700px) {
  .fontsize-h4 {
    font-size: 1.1rem;
  }
}
@media screen and (min-width: 700px) and (max-width: 800px) {
  .fontsize-h4 {
    font-size: 1.1rem;
  }
}
.fontsize-p-list {
  font-size: 0.91rem;
}
@media screen and (max-width: 700px) {
  .fontsize-p-list {
    font-size: 0.8rem;
  }
}
@media screen and (min-width: 700px) and (max-width: 800px) {
  .fontsize-p-list {
    font-size: 0.8rem;
  }
}
.fontsize-detail {
  font-size: 0.6rem;
}
@media screen and (max-width: 700px) {
  .fontsize-detail {
    font-size: 0.6rem;
  }
}
@media screen and (min-width: 700px) and (max-width: 800px) {
  .fontsize-detail {
    font-size: 0.6rem;
  }
}
.fontsize-stage-p {
  font-size: 1.2rem;
}
@media screen and (max-width: 700px) {
  .fontsize-stage-p {
    font-size: 1.2rem;
  }
}
@media screen and (min-width: 700px) and (max-width: 800px) {
  .fontsize-stage-p {
    font-size: 1.2rem;
  }
}
.fontsize-carousel-heading {
  font-size: 1.25rem;
}
@media screen and (max-width: 700px) {
  .fontsize-carousel-heading {
    font-size: 1.4rem;
  }
}
@media screen and (min-width: 700px) and (max-width: 800px) {
  .fontsize-carousel-heading {
    font-size: 1.4rem;
  }
}
.fontsize-carousel-p {
  font-size: 0.91rem;
}
@media screen and (max-width: 700px) {
  .fontsize-carousel-p {
    font-size: 1rem;
  }
}
@media screen and (min-width: 700px) and (max-width: 800px) {
  .fontsize-carousel-p {
    font-size: 1rem;
  }
}
.fontsize-faq-categories {
  font-size: 1.3rem;
}
@media screen and (max-width: 700px) {
  .fontsize-faq-categories {
    font-size: 1.3rem;
  }
}
@media screen and (min-width: 700px) and (max-width: 800px) {
  .fontsize-faq-categories {
    font-size: 1.3rem;
  }
}
h1 {
  font-family: 'Mitr';
  font-size: 2.2rem;
  color: #FFCC00;
}
@media screen and (max-width: 700px) {
  h1 {
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 700px) and (max-width: 800px) {
  h1 {
    font-size: 1.5rem;
  }
}
h2 {
  font-family: 'Mitr';
  font-size: 1.2rem;
  color: #000033;
}
@media screen and (max-width: 700px) {
  h2 {
    font-size: 1.3rem;
  }
}
@media screen and (min-width: 700px) and (max-width: 800px) {
  h2 {
    font-size: 1.3rem;
  }
}
#text > h2,
.section-wrapper > h2 {
  margin-top: 20px;
  margin-bottom: 10px;
}
h3 {
  font-family: 'Mitr';
  font-size: 1rem;
  color: #000033;
}
@media screen and (max-width: 700px) {
  h3 {
    font-size: 1.1rem;
  }
}
@media screen and (min-width: 700px) and (max-width: 800px) {
  h3 {
    font-size: 1.1rem;
  }
}
#text > h3,
.section-wrapper > h3 {
  margin-top: 10px;
  margin-bottom: 10px;
}
h4 {
  font-family: 'Mitr';
  font-size: 1rem;
  color: #000033;
}
@media screen and (max-width: 700px) {
  h4 {
    font-size: 1.1rem;
  }
}
@media screen and (min-width: 700px) and (max-width: 800px) {
  h4 {
    font-size: 1.1rem;
  }
}
p {
  font-family: 'Lato';
  font-size: 0.91rem;
  line-height: 1.4;
  color: #000033;
  margin-bottom: 10px;
  word-break: break-word;
}
@media screen and (max-width: 700px) {
  p {
    font-size: 0.9rem;
  }
}
@media screen and (min-width: 700px) and (max-width: 800px) {
  p {
    font-size: 0.9rem;
  }
}
p a {
  font-size: 0.91rem;
  color: #000033;
  text-decoration: underline;
}
@media screen and (max-width: 700px) {
  p a {
    font-size: 0.9rem;
  }
}
@media screen and (min-width: 700px) and (max-width: 800px) {
  p a {
    font-size: 0.9rem;
  }
}
p a:hover {
  color: #FF9999;
}
a {
  font-family: 'mr';
  font-size: 0.91rem;
  color: #0000cc;
  text-decoration: none;
}
@media screen and (max-width: 700px) {
  a {
    font-size: 0.9rem;
  }
}
@media screen and (min-width: 700px) and (max-width: 800px) {
  a {
    font-size: 0.9rem;
  }
}
a:hover {
  color: #ff9999;
}
#text a {
  font-family: 'Lato';
}
ul {
  font-family: 'mb';
  font-size: 0.91rem;
  line-height: 1.4;
  list-style-position: inside;
  list-style: none;
  margin-bottom: 10px;
  margin-left: 20px;
  color: #000033;
}
@media screen and (max-width: 700px) {
  ul {
    font-size: 0.8rem;
  }
}
@media screen and (min-width: 700px) and (max-width: 800px) {
  ul {
    font-size: 0.8rem;
  }
}
ul a {
  font-family: 'mb';
  font-size: 0.91rem;
  color: #000033;
  text-decoration: underline;
}
@media screen and (max-width: 700px) {
  ul a {
    font-size: 0.9rem;
  }
}
@media screen and (min-width: 700px) and (max-width: 800px) {
  ul a {
    font-size: 0.9rem;
  }
}
ul a:hover {
  color: #FF9999;
}
#content ul {
  list-style-type: disc;
}
#content ul li {
  margin-left: 2em;
}
address {
  font-family: 'mr';
  line-height: 1.4;
  font-style: normal;
}
pre {
  font-family: 'mr';
  line-height: 1.4;
  font-style: normal;
  white-space: pre-wrap;
}
.stage-teasertext {
  font-family: 'ml';
  font-size: 1.2rem;
  letter-spacing: 0.6px;
  line-height: 1.5;
  padding-bottom: 0;
}
@media screen and (max-width: 700px) {
  .stage-teasertext {
    font-size: 1.2rem;
  }
}
@media screen and (min-width: 700px) and (max-width: 800px) {
  .stage-teasertext {
    font-size: 1.2rem;
  }
}
.boxes-carousel-heading {
  font-family: 'msb';
  font-size: 1.25rem;
}
@media screen and (max-width: 700px) {
  .boxes-carousel-heading {
    font-size: 1.4rem;
  }
}
@media screen and (min-width: 700px) and (max-width: 800px) {
  .boxes-carousel-heading {
    font-size: 1.4rem;
  }
}
.boxes-carousel-p {
  font-family: 'or';
  font-size: 0.91rem;
  margin-bottom: 0;
  color: #000033;
}
@media screen and (max-width: 700px) {
  .boxes-carousel-p {
    font-size: 1rem;
  }
}
@media screen and (min-width: 700px) and (max-width: 800px) {
  .boxes-carousel-p {
    font-size: 1rem;
  }
}
.boxes-carousel-boxtype {
  font-family: 'mr';
  font-size: 0.85rem;
}
.boxes-h1 {
  font-family: 'ml';
  font-size: 0.85rem;
}
.boxes-h4 {
  font-family: 'mb';
  font-size: 1rem;
  padding-top: 10px;
  padding-bottom: 10px;
}
.boxes-p {
  font-family: 'mr';
  font-size: 0.91rem;
  margin-bottom: 0;
  color: #000033;
}
.boxes-event-link {
  font-family: 'mr';
  font-size: 0.75rem;
  margin-bottom: 0;
  color: white;
}
.big-teaser-text {
  font-family: 'meb';
  font-size: 1.2rem;
  letter-spacing: 0.6px;
  line-height: 1.5;
  padding-bottom: 0;
}
@media screen and (max-width: 700px) {
  .big-teaser-text {
    font-size: 1.2rem;
  }
}
@media screen and (min-width: 700px) and (max-width: 800px) {
  .big-teaser-text {
    font-size: 1.2rem;
  }
}
.faq-heading {
  font-family: 'msb';
  font-size: 1.3rem;
  color: #ffffff;
}
@media screen and (max-width: 700px) {
  .faq-heading {
    font-size: 1.3rem;
  }
}
@media screen and (min-width: 700px) and (max-width: 800px) {
  .faq-heading {
    font-size: 1.3rem;
  }
}
.faq-question-text {
  font-family: 'msb';
  font-size: 0.91rem;
  letter-spacing: 0.6px;
  line-height: 1.2;
  color: #000033;
}
@media screen and (max-width: 700px) {
  .faq-question-text {
    font-size: 0.9rem;
  }
}
@media screen and (min-width: 700px) and (max-width: 800px) {
  .faq-question-text {
    font-size: 0.9rem;
  }
}
.faq-answer-text {
  font-family: 'ml';
  font-size: 0.91rem;
  letter-spacing: 0.6px;
  line-height: 1.2;
  color: #FFFFFF;
}
@media screen and (max-width: 700px) {
  .faq-answer-text {
    font-size: 0.9rem;
  }
}
@media screen and (min-width: 700px) and (max-width: 800px) {
  .faq-answer-text {
    font-size: 0.9rem;
  }
}
.error {
  color: #DE1224;
}
h2 a,
h3 a,
h4 a,
h5 a {
  font-size: 0.91rem;
  color: #000033;
  text-decoration: underline;
  font-family: 'mb';
}
@media screen and (max-width: 700px) {
  h2 a,
  h3 a,
  h4 a,
  h5 a {
    font-size: 0.9rem;
  }
}
@media screen and (min-width: 700px) and (max-width: 800px) {
  h2 a,
  h3 a,
  h4 a,
  h5 a {
    font-size: 0.9rem;
  }
}
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover {
  color: #FF9999;
}
.datedisplay {
  font-family: 'mi';
  font-size: 0.91rem;
  margin-bottom: 5px;
}
@media screen and (max-width: 700px) {
  .datedisplay {
    font-size: 0.9rem;
  }
}
@media screen and (min-width: 700px) and (max-width: 800px) {
  .datedisplay {
    font-size: 0.9rem;
  }
}
.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.boxsettings {
  border-radius: 9px;
}
.slider-buttons {
  position: absolute;
  top: 43%;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  color: #FFCC00;
  padding: 16px 16px;
  text-align: center;
  font-size: 2.2rem;
  margin: 4px 2px;
  display: inline-block;
  text-decoration: none;
}
.slider-buttons.left {
  left: 10px;
}
.slider-buttons.right {
  right: 10px;
  float: right;
}
.full-width-teaser-heading {
  max-width: 800px;
  color: #FFFFFF;
  background-color: #FFCC00;
  border-radius: 9px;
  padding: 20px;
  margin: 30px 30px;
}
@media screen and (min-width: 800px) {
  .full-width-teaser-heading {
    width: max-content;
  }
}
.full-width-teaser-img-text {
  max-width: 800px;
  color: #FFFFFF;
  background-color: #FFCC00;
  border-radius: 9px;
  padding: 20px;
  margin: 30px 30px;
}
@media screen and (min-width: 800px) {
  .full-width-teaser-img-text {
    width: max-content;
  }
}
@media screen and (max-width: 700px) {
  .full-width-teaser-img-text {
    font-size: 1.1rem;
    margin: 20px 10px;
  }
}
.full-width-teaser-img-text h1 {
  color: white;
  display: block;
  font-family: 'msb';
  font-size: 1.25rem;
}
@media screen and (max-width: 700px) {
  .full-width-teaser-img-text h1 {
    font-size: 1.4rem;
  }
}
@media screen and (min-width: 700px) and (max-width: 800px) {
  .full-width-teaser-img-text h1 {
    font-size: 1.4rem;
  }
}
.full-width-teaser-img-text .teasertext {
  font-family: 'or';
  font-size: 0.91rem;
  margin-bottom: 0;
  color: #000033;
}
@media screen and (max-width: 700px) {
  .full-width-teaser-img-text .teasertext {
    font-size: 1rem;
  }
}
@media screen and (min-width: 700px) and (max-width: 800px) {
  .full-width-teaser-img-text .teasertext {
    font-size: 1rem;
  }
}
@font-face {
  font-family: 'ebkita-icons';
  src: url('fonts/ebkita-icons.eot?58257107');
  src: url('fonts/ebkita-icons.eot?58257107#iefix') format('embedded-opentype'), url('fonts/ebkita-icons.woff2?58257107') format('woff2'), url('fonts/ebkita-icons.woff?58257107') format('woff'), url('fonts/ebkita-icons.ttf?58257107') format('truetype'), url('fonts/ebkita-icons.svg?58257107#ebkita-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'ebkita-icons';
    src: url('fonts/ebkita-icons.svg?58257107#ebkita-icons') format('svg');
  }
}
*/
[class^="icon-ebk-"]:before,
[class*=" icon-ebk-"]:before {
  font-family: "ebkita-icons";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
.icon-ebk-plus:before {
  content: '\e800';
}
/* '' */
.icon-ebk-down-dir:before {
  content: '\e801';
}
/* '' */
.icon-ebk-up-dir:before {
  content: '\e802';
}
/* '' */
.icon-ebk-left-dir:before {
  content: '\e803';
}
/* '' */
.icon-ebk-right-dir:before {
  content: '\e804';
}
/* '' */
.icon-ebk-down-open:before {
  content: '\e805';
}
/* '' */
.icon-ebk-left-open:before {
  content: '\e806';
}
/* '' */
.icon-ebk-right-open:before {
  content: '\e807';
}
/* '' */
.icon-ebk-up-open:before {
  content: '\e808';
}
/* '' */
.icon-ebk-cancel:before {
  content: '\e809';
}
/* '' */
.icon-ebk-cancel-1:before {
  content: '\e80a';
}
/* '' */
.icon-ebk-filter:before {
  content: '\f0b0';
}
/* '' */
.icon-ebk-menu:before {
  content: '\f0c9';
}
/* '' */
.icon-ebk-doc-text:before {
  content: '\f0f6';
}
/* '' */
.icon-ebk-angle-left:before {
  content: '\f104';
}
/* '' */
.icon-ebk-angle-right:before {
  content: '\f105';
}
/* '' */
.icon-ebk-angle-up:before {
  content: '\f106';
}
/* '' */
.icon-ebk-angle-down:before {
  content: '\f107';
}
/* '' */
.icon-ebk-doc-inv:before {
  content: '\f15b';
}
/* '' */
.icon-ebk-doc-text-inv:before {
  content: '\f15c';
}
/* '' */
.icon-ebk-file-image:before {
  content: '\f1c5';
}
/* '' */
.icon-ebk-flickr:before {
  content: '\f303';
}
/* '' */
#menu {
  z-index: 10;
  width: 100%;
  position: fixed;
  top: 0;
  display: flex;
  background-color: #DE1224;
}
@supports (display: grid) {
  #menu {
    display: grid;
    grid-template-columns: minmax(30px, auto) minmax(300px, 1170px) minmax(30px, auto);
    grid-template-rows: auto auto;
    background-color: transparent;
  }
  @media screen and (max-width: 700px) {
    #menu {
      grid-template-columns: minmax(20px, auto) minmax(200px, 1170px) minmax(20px, auto);
    }
  }
}
.fill {
  width: 100%;
  background-color: #DE1224;
  flex-basis: 30px;
}
.fill:first-of-type {
  order: 1;
}
.fill:nth-child(3) {
  order: 4;
}
.menu-hoversettings {
  animation-name: play24;
}
.menu {
  grid-column-start: 2;
  align-self: start;
  align-items: center;
  background-color: #DE1224;
  width: inherit;
  order: 2;
  display: flex;
  flex-wrap: wrap;
}
@supports (display: grid) {
  .menu {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: repaeat(2, auto);
    column-gap: 10px;
  }
  @media screen and (max-width: 700px) {
    .menu {
      grid-template-columns: 2fr 1fr;
      grid-template-rows: auto;
    }
  }
}
@media screen and (min-width: 800px) {
  .menu.active + .fill + .shapeshifter,
  .menu:hover + .fill + .shapeshifter {
    animation-name: play24;
  }
}
@media screen and (max-width: 800px) {
  .menu.active + .fill + .shapeshifter {
    animation-name: play24;
  }
}
.logo-link-hover-settings {
  padding: 8px 0px 8px 0px;
}
a.logo {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  padding: 8px 0px 8px 0px;
  flex-basis: 100%;
  transition: padding 250ms ease;
}
@media screen and (max-width: 700px) {
  a.logo {
    grid-column-end: 4;
  }
  .scroll a.logo {
    padding: 0;
  }
}
.scroll a.logo {
  padding: 4px 0;
}
@media screen and (min-width: 800px) {
  .menu:hover a.logo,
  .menu.active a.logo {
    padding: 8px 0px 8px 0px;
  }
}
@media screen and (max-width: 800px) {
  .menu.active a.logo {
    padding: 8px 0px 8px 0px;
  }
}
#logo {
  position: relative;
  height: 70px;
  background-image: url('svg/EB-Kita-Logo-white-all.svg');
  background-repeat: no-repeat;
  background-size: 320px 70px;
  transition: height 250ms ease, background-size 250ms ease;
}
.scroll #logo {
  height: 35px;
  background-size: 130px 35px;
}
@media screen and (min-width: 800px) {
  .menu:hover #logo,
  .menu.active #logo {
    height: 70px;
    background-size: 320px 70px;
  }
}
@media screen and (max-width: 800px) {
  #logo {
    height: 54px;
    background-size: 250px 54px;
  }
  .scroll #logo {
    height: 0;
    background-size: 0px 0px;
  }
  .menu.active #logo {
    height: 70px;
    background-size: 250px 54px;
  }
}
.logo-animate-hoversettings {
  width: calc(100% - 70px);
  opacity: 0;
  left: 70px;
}
#logo-animate {
  position: absolute;
  height: 100%;
  opacity: 0;
  background-color: #DE1224;
  width: calc(100% - 70px);
  transition: width 250ms ease, left 250ms ease, opacity 250ms ease;
  left: 70px;
}
.scroll #logo-animate {
  width: calc(100% - 35px);
  opacity: 1;
  left: 35px;
}
@media screen and (min-width: 800px) {
  .menu:hover #logo-animate,
  .menu.active #logo-animate {
    width: calc(100% - 70px);
    opacity: 0;
    left: 70px;
  }
}
@media screen and (max-width: 800px) {
  .menu.active #logo-animate {
    width: calc(100% - 70px);
    opacity: 0;
    left: 70px;
  }
}
.menu-columns-hoversettings {
  max-height: 600px;
  overflow: initial;
  padding: 30px 0 30px 0;
}
@media screen and (max-width: 700px) {
  .menu-columns-hoversettings {
    padding: 5px 0;
  }
}
.menu-columns-hoversettings ul,
.menu-columns-hoversettings li,
.menu-columns-hoversettings a {
  opacity: 1;
}
@supports (display: grid) {
  .menu-columns-hoversettings {
    height: initial;
  }
}
.menu-colums {
  flex-basis: 33.3%;
  max-height: 0;
  overflow: hidden;
  grid-row-start: 2;
  transition: padding 70ms, max-height 70ms;
}
@media screen and (min-width: 800px) {
  .menu:hover .menu-colums,
  .menu.active .menu-colums {
    max-height: 600px;
    overflow: initial;
    padding: 30px 0 30px 0;
  }
  .menu:hover .menu-colums ul,
  .menu.active .menu-colums ul,
  .menu:hover .menu-colums li,
  .menu.active .menu-colums li,
  .menu:hover .menu-colums a,
  .menu.active .menu-colums a {
    opacity: 1;
  }
  @supports (display: grid) {
    .menu:hover .menu-colums,
    .menu.active .menu-colums {
      height: initial;
    }
  }
}
@media screen and (min-width: 800px) and screen and (max-width: 700px) {
  .menu:hover .menu-colums,
  .menu.active .menu-colums {
    padding: 5px 0;
  }
}
@media screen and (max-width: 800px) {
  .menu.active .menu-colums {
    max-height: 600px;
    overflow: initial;
    padding: 30px 0 30px 0;
  }
  .menu.active .menu-colums ul,
  .menu.active .menu-colums li,
  .menu.active .menu-colums a {
    opacity: 1;
  }
  @supports (display: grid) {
    .menu.active .menu-colums {
      height: initial;
    }
  }
}
@media screen and (max-width: 800px) and screen and (max-width: 700px) {
  .menu.active .menu-colums {
    padding: 5px 0;
  }
}
@supports (display: grid) {
  .menu-colums {
    height: 0;
    transition: padding 70ms, height 70ms;
  }
}
.menu-colums ul {
  margin-bottom: 10px;
  margin-left: 0px;
}
.menu-colums ul,
.menu-colums li,
.menu-colums a {
  font-family: 'mr';
  line-height: 1.8;
  color: #ffffff;
  font-size: 1rem;
  text-decoration: none;
  opacity: 0;
  transition: opacity 30ms 70ms;
}
@media screen and (max-width: 800px) {
  .menu-colums ul,
  .menu-colums li,
  .menu-colums a {
    font-size: 0.9rem;
  }
}
@media screen and (max-width: 700px) {
  .menu-colums ul,
  .menu-colums li,
  .menu-colums a {
    padding-bottom: 0;
  }
}
.menu-colums a:hover {
  color: #FF9999;
}
.menu-colums li.active a {
  color: #FF9999;
}
@media screen and (max-width: 700px) {
  .menu-colums {
    grid-row-start: initial;
    grid-column-start: 1;
    padding: 0;
  }
}
@keyframes play24 {
  0% {
    background-position: 0px 0px;
  }
  100% {
    background-position: -2400px 0px;
  }
}
.shapeshifter {
  position: absolute;
  bottom: -50px;
  right: 30px;
  order: 3;
  animation-duration: 400ms;
  animation-timing-function: steps(24);
  animation-fill-mode: forwards;
  width: 100px;
  height: 100px;
  background-repeat: no-repeat;
  background-image: url('svg/plus_minus_arrow.svg');
}
@supports (display: grid) {
  .shapeshifter {
    position: initial;
    grid-column-start: 2;
    justify-self: end;
    margin-top: -50px;
    grid-row-start: 2;
    bottom: initial;
    right: initial;
  }
}
@media screen and (max-width: 800px) {
  .shapeshifter {
    transform: scale(0.8);
  }
}
@media screen and (max-width: 700px) {
  .shapeshifter {
    transform: scale(0.6);
  }
}
.navitem.new-section {
  margin-top: 1.8rem;
}
@media screen and (max-width: 800px) {
  .navitem.new-section {
    margin-top: 1.6rem;
  }
}
#stage {
  background-color: #F2F2FB;
  margin-top: 70px;
  position: relative;
  min-height: 150px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.portaltype-startseite #stage {
  height: calc(100vh - 70px);
}
.portaltype-startseite #stage .stage-image {
  height: inherit;
}
.multistage.item {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.multistage .text-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: inherit;
  max-width: 1230px;
}
.text-wrapper {
  max-width: 1230px;
  width: 100%;
  margin: auto;
  z-index: 1;
}
.stage-documentFirstHeading {
  max-width: 840px;
  margin: 30px 30px 40px 30px;
  background-color: #000033;
  border-radius: 9px;
  padding: 10px;
}
.stage-documentFirstHeading h1 {
  color: #FFFFFF;
}
.portaltype-startseite .stage-documentFirstHeading {
  background-color: #FFCC00;
}
.portaltype-startseite .stage-documentFirstHeading h1 {
  color: #000033;
}
@media screen and (min-width: 800px) {
  .stage-documentFirstHeading {
    width: max-content;
  }
}
.stage-full-width-img-text {
  max-width: 840px;
  margin: 30px 30px 40px 30px;
  background-color: #000033;
  border-radius: 9px;
  padding: 10px;
}
.stage-full-width-img-text h1 {
  color: #FFFFFF;
}
.portaltype-startseite .stage-full-width-img-text {
  background-color: #FFCC00;
}
.portaltype-startseite .stage-full-width-img-text h1 {
  color: #000033;
}
@media screen and (min-width: 800px) {
  .stage-full-width-img-text {
    width: max-content;
  }
}
@media screen and (max-width: 700px) {
  .stage-full-width-img-text {
    font-size: 1.1rem;
    margin: 20px 10px;
  }
}
.stage-full-width-img-text .teasertext {
  font-family: 'ml';
  font-size: 1.2rem;
  letter-spacing: 0.6px;
  line-height: 1.5;
  padding-bottom: 0;
  padding: 0;
}
@media screen and (max-width: 700px) {
  .stage-full-width-img-text .teasertext {
    font-size: 1.2rem;
  }
}
@media screen and (min-width: 700px) and (max-width: 800px) {
  .stage-full-width-img-text .teasertext {
    font-size: 1.2rem;
  }
}
.documentFirstHeading {
  max-width: 840px;
  margin: 30px 30px 40px 30px;
  background-color: #000033;
  border-radius: 9px;
  padding: 10px;
}
.documentFirstHeading h1 {
  color: #FFFFFF;
}
.portaltype-startseite .documentFirstHeading {
  background-color: #FFCC00;
}
.portaltype-startseite .documentFirstHeading h1 {
  color: #000033;
}
@media screen and (min-width: 800px) {
  .documentFirstHeading {
    width: max-content;
  }
}
@media screen and (max-width: 700px) {
  .documentFirstHeading {
    font-size: 1.1rem;
    margin: 20px 10px;
  }
}
.documentFirstHeading .teasertext {
  font-family: 'ml';
  font-size: 1.2rem;
  letter-spacing: 0.6px;
  line-height: 1.5;
  padding-bottom: 0;
  padding: 0;
}
@media screen and (max-width: 700px) {
  .documentFirstHeading .teasertext {
    font-size: 1.2rem;
  }
}
@media screen and (min-width: 700px) and (max-width: 800px) {
  .documentFirstHeading .teasertext {
    font-size: 1.2rem;
  }
}
.stage-text {
  max-width: 840px;
  margin: 30px 30px 40px 30px;
  background-color: #000033;
  border-radius: 9px;
  padding: 10px;
}
.stage-text h1 {
  color: #FFFFFF;
}
.portaltype-startseite .stage-text {
  background-color: #FFCC00;
}
.portaltype-startseite .stage-text h1 {
  color: #000033;
}
@media screen and (min-width: 800px) {
  .stage-text {
    width: max-content;
  }
}
@media screen and (max-width: 700px) {
  .stage-text {
    font-size: 1.1rem;
    margin: 20px 10px;
  }
}
.stage-text .teasertext {
  font-family: 'ml';
  font-size: 1.2rem;
  letter-spacing: 0.6px;
  line-height: 1.5;
  padding-bottom: 0;
  padding: 0;
}
@media screen and (max-width: 700px) {
  .stage-text .teasertext {
    font-size: 1.2rem;
  }
}
@media screen and (min-width: 700px) and (max-width: 800px) {
  .stage-text .teasertext {
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 700px) {
  .stage-text {
    margin: 20px 10px 40px 10px;
  }
}
.stage-image {
  width: inherit;
  height: calc(70vh - 70px);
}
.stage-image > div {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.multistage-article-text {
  position: absolute;
  bottom: 0;
}
#statusmessage {
  background-color: #F2F2FB;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
#statusmessage .section-wrapper {
  margin: 0 10px;
  width: 100%;
  max-width: 1170px;
}
#statusmessage > * {
  flex-basis: 488px;
}
.portalMessage,
#global_statusmessage {
  max-width: 750px;
  font-family: 'or';
  font-size: 1rem;
  line-height: 1.4;
}
@media screen and (max-width: 700px) {
  .portalMessage,
  #global_statusmessage {
    font-size: 1.1rem;
  }
}
@media screen and (min-width: 700px) and (max-width: 800px) {
  .portalMessage,
  #global_statusmessage {
    font-size: 1.1rem;
  }
}
.portalMessage strong,
#global_statusmessage strong {
  display: none;
}
.portalMessage.error {
  margin: 20px;
}
#intro,
#content_1,
#content_2 {
  background-color: #F2F2FB;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
#intro .section-wrapper,
#content_1 .section-wrapper,
#content_2 .section-wrapper {
  max-width: 1170px;
  margin: 20px 10px;
  overflow: hidden;
}
#intro .section-wrapper.ebkita-article,
#content_1 .section-wrapper.ebkita-article,
#content_2 .section-wrapper.ebkita-article {
  grid-template-columns: 1fr;
}
#content_1 .section-wrapper,
#content_2 .section-wrapper {
  max-width: 750px;
}
#intro .section-wrapper {
  display: grid;
  display: -ms-grid;
  grid-template-columns: 2fr 1fr;
  -ms-grid-columns: 2fr 1fr;
  grid-template-rows: auto auto;
  grid-gap: 20px;
  padding-right: 20px;
}
@media screen and (max-width: 700px) {
  #intro .section-wrapper {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
  }
}
@supports (display: grid) {
  #intro .section-wrapper {
    padding-right: initial;
  }
}
#text {
  grid-column-start: 1;
  -ms-grid-column: 1;
  grid-row-start: 1;
  -ms-grid-row: 1;
  grid-row-end: 3;
  -ms-grid-row-span: 3;
  overflow: hidden;
  align-self: start;
  max-width: 750px;
  padding-bottom: 30px;
}
@media screen and (max-width: 700px) {
  #text {
    grid-row-start: 1;
    grid-row-end: 1;
  }
}
@media screen and (max-width: 700px) {
  .portaltype-einrichtung #text {
    grid-row-start: 2;
    grid-row-end: 2;
  }
}
#box-container-mobile,
#box-container {
  display: flex;
  flex-direction: column;
}
#box-container-mobile .carousel.item,
#box-container .carousel.item {
  margin: 10px 0;
  max-width: initial;
}
#facility-metadata-mobile,
#facility-metadata,
#project-metadata {
  align-self: start;
  padding: 10px;
  -ms-grid-column: 2;
  margin-bottom: 10px;
  width: calc(100% - 20px);
}
#files,
#facility-metadata-mobile,
#facility-metadata,
#project-metadata,
#event-metadata {
  background-color: #C2C2D6;
  border-radius: 9px;
}
#facility-metadata-mobile,
#facility-metadata {
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 700px) {
  #facility-metadata {
    display: none;
  }
}
#box-container-mobile {
  display: none;
}
@media screen and (max-width: 700px) {
  #box-container-mobile {
    grid-row-start: 1;
    grid-row-end: 1;
    display: flex;
  }
}
#announcements a {
  color: #000033;
}
#files {
  width: 100%;
  margin: 10px 0;
}
#address,
#contact,
#misc_data,
#maps_link,
#date {
  align-self: start;
  padding: 10px;
}
#address p,
#contact p,
#misc_data p,
#maps_link p,
#date p {
  font-family: 'mr';
  font-size: 0.91rem;
  margin-bottom: 0;
  color: #000033;
}
#address strong,
#contact strong,
#misc_data strong,
#maps_link strong,
#date strong {
  display: block;
  margin-bottom: 0.7ex;
}
#address address,
#contact address,
#misc_data address,
#maps_link address,
#date address {
  margin-bottom: 1.5ex;
}
#event-metadata {
  -ms-grid-column: 2;
  padding: 10px;
}
#address {
  font-family: 'mr';
  font-size: 0.91rem;
  margin-bottom: 0;
  color: #000033;
}
#contact {
  grid-row-start: contact;
  font-family: 'mr';
  font-size: 0.91rem;
  margin-bottom: 0;
  color: #000033;
}
#contact .facility_size {
  margin-top: 1.5ex;
}
#event-metadata > #date > .event-date {
  padding: 0;
}
#maps_link {
  background-image: url('images/maps_icon.svg');
  background-repeat: no-repeat;
  background-position: 10px 0px;
  background-size: 23px;
  padding-left: 40px;
  margin-top: 8px;
}
#event-metadata > #contact {
  padding-top: 10px / 2;
  padding-bottom: 10px / 2;
}
#misc_data {
  grid-row-start: misc_data;
  font-family: 'mr';
  font-size: 0.91rem;
  margin-bottom: 0;
  color: #000033;
}
.event-date,
.event-location {
  font-family: 'mr';
  font-size: 0.91rem;
  margin-bottom: 0;
  color: #000033;
}
.announcement-link {
  margin-top: 10px;
}
.announcement-link .file-link span {
  vertical-align: top;
}
.announcement-link .file-link a {
  display: inline-block;
  width: 90%;
}
#facility-logo .logoimg img {
  height: auto;
}
.fontset-teaser-box-type {
  font-family: 'mr';
  font-size: 0.85rem;
}
.fontset-teasertitle {
  font-family: 'msb';
  font-size: 1.25rem;
}
@media screen and (max-width: 800px) {
  .fontset-teasertitle {
    font-size: 1.4rem;
  }
}
.fontset-teasertext {
  font-family: 'or';
  font-size: 0.91rem;
}
@media screen and (max-width: 800px) {
  .fontset-teasertext {
    font-size: 1rem;
  }
}
.teaser-tile {
  border-radius: 9px;
  align-self: start;
  flex: 1 1 300px;
  max-width: 370px;
  margin: 0.6rem;
}
.teaser-tile.Einrichtung {
  background-color: #C2C2D6;
  color: #000033;
}
.teaser-tile.Einrichtung a {
  color: #000033;
}
.teaser-tile.Projekt {
  background-color: #C2C2D6;
  color: #000033;
}
.teaser-tile.Projekt a {
  color: #000033;
}
.teaser-tile.Artikel {
  background-color: #FF9999;
  color: #000033;
}
.teaser-tile.Artikel a {
  color: #000033;
}
.teaser-tile.Unterseite {
  background-color: #D6C2CC;
  color: #000033;
}
.teaser-tile.Unterseite a {
  color: #000033;
}
.teaser-tile.FAQKategorie {
  background-color: #C2C2D6;
  color: #000033;
}
.teaser-tile.FAQKategorie a {
  color: #000033;
}
.teaser-tile.event {
  background-color: #FFCC00;
  color: #000033;
}
.teaser-tile.event a {
  color: #000033;
}
.teaser-tile.Meldung {
  background-color: #FFCC00;
  color: #000033;
}
.teaser-tile.Meldung a {
  color: #000033;
}
.teaser-tile.Folder {
  background-color: #C2C2D6;
  color: #000033;
}
.teaser-tile.Folder a {
  color: #000033;
}
.teaser-tile-content {
  display: flex;
  flex-direction: column;
}
.teaser-tile-content > * {
  flex: 0 0 auto;
}
.teaser-tile-content img {
  width: 100%;
  height: auto;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
}
.box-type {
  order: 1;
  padding: 0.8rem 1.2rem 0 1.2rem;
  margin-bottom: -0.2rem;
  font-family: 'mr';
  font-size: 0.85rem;
}
.teasertitle {
  order: 2;
  padding: 0.8rem 0.6rem 0.6rem 1.2rem;
  font-family: 'msb';
  font-size: 1.25rem;
}
@media screen and (max-width: 800px) {
  .teasertitle {
    font-size: 1.4rem;
  }
}
.teasertitle > h4 {
  font-family: 'msb';
  font-size: 1.25rem;
}
@media screen and (max-width: 800px) {
  .teasertitle > h4 {
    font-size: 1.4rem;
  }
}
.teasertext {
  order: 3;
  padding: 0 1.2rem 1.2rem 1.2rem;
  font-family: 'or';
  font-size: 0.91rem;
}
.teasertext:empty {
  padding-bottom: 0.25rem;
}
@media screen and (max-width: 800px) {
  .teasertext {
    font-size: 1rem;
  }
}
.carousel.item {
  border-radius: 9px;
  align-self: start;
  flex: 1 1 300px;
  max-width: 370px;
  margin: 0.6rem;
}
.carousel.item.Einrichtung {
  background-color: #C2C2D6;
  color: #000033;
}
.carousel.item.Einrichtung a {
  color: #000033;
}
.carousel.item.Projekt {
  background-color: #C2C2D6;
  color: #000033;
}
.carousel.item.Projekt a {
  color: #000033;
}
.carousel.item.Artikel {
  background-color: #FF9999;
  color: #000033;
}
.carousel.item.Artikel a {
  color: #000033;
}
.carousel.item.Unterseite {
  background-color: #D6C2CC;
  color: #000033;
}
.carousel.item.Unterseite a {
  color: #000033;
}
.carousel.item.FAQKategorie {
  background-color: #C2C2D6;
  color: #000033;
}
.carousel.item.FAQKategorie a {
  color: #000033;
}
.carousel.item.event {
  background-color: #FFCC00;
  color: #000033;
}
.carousel.item.event a {
  color: #000033;
}
.carousel.item.Meldung {
  background-color: #FFCC00;
  color: #000033;
}
.carousel.item.Meldung a {
  color: #000033;
}
.carousel.item.Folder {
  background-color: #C2C2D6;
  color: #000033;
}
.carousel.item.Folder a {
  color: #000033;
}
.carousel.item .content {
  display: flex;
  flex-direction: column;
}
.carousel.item .content > * {
  flex: 0 0 auto;
}
.carousel.item .content img {
  width: 100%;
  height: auto;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
}
div.event {
  border-radius: 9px;
  align-self: start;
  flex: 1 1 300px;
  max-width: 370px;
  margin: 0.6rem;
  display: flex;
  flex-direction: column;
}
div.event.Einrichtung {
  background-color: #C2C2D6;
  color: #000033;
}
div.event.Einrichtung a {
  color: #000033;
}
div.event.Projekt {
  background-color: #C2C2D6;
  color: #000033;
}
div.event.Projekt a {
  color: #000033;
}
div.event.Artikel {
  background-color: #FF9999;
  color: #000033;
}
div.event.Artikel a {
  color: #000033;
}
div.event.Unterseite {
  background-color: #D6C2CC;
  color: #000033;
}
div.event.Unterseite a {
  color: #000033;
}
div.event.FAQKategorie {
  background-color: #C2C2D6;
  color: #000033;
}
div.event.FAQKategorie a {
  color: #000033;
}
div.event.event {
  background-color: #FFCC00;
  color: #000033;
}
div.event.event a {
  color: #000033;
}
div.event.Meldung {
  background-color: #FFCC00;
  color: #000033;
}
div.event.Meldung a {
  color: #000033;
}
div.event.Folder {
  background-color: #C2C2D6;
  color: #000033;
}
div.event.Folder a {
  color: #000033;
}
div.event > * {
  flex: 0 0 auto;
}
div.event img {
  width: 100%;
  height: auto;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
}
div.event > .teaserimg {
  order: 4;
  display: flex;
  justify-content: center;
  grid-row-start: 5;
  padding: 0 1.2rem 1.2rem 1.2rem;
}
div.event > .teaserimg > img {
  width: 100%;
  height: 100%;
  border-radius: 0;
}
.event-date {
  order: 2;
  grid-row-start: 3;
  color: #000033;
  font-family: 'mr';
  font-size: 0.85rem;
  margin-bottom: 8px;
  padding: 0 1.2rem;
}
#events,
#teaser-carousel-1,
#teaser-carousel-2 {
  background-color: #F2F2FB;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
#events > .section-wrapper,
#teaser-carousel-1 > .section-wrapper,
#teaser-carousel-2 > .section-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 1170px;
  width: 100%;
  margin: 20px 10px;
}
@supports (display: grid) {
  #events > .section-wrapper,
  #teaser-carousel-1 > .section-wrapper,
  #teaser-carousel-2 > .section-wrapper {
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-auto-rows: 20px;
  }
  @media screen and (max-width: 700px) {
    #events > .section-wrapper,
    #teaser-carousel-1 > .section-wrapper,
    #teaser-carousel-2 > .section-wrapper {
      grid-template-columns: 1fr;
    }
  }
}
#events > .section-wrapper {
  grid-auto-rows: initial;
}
#teaser-wrapper {
  background-color: #F2F2FB;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.article-teaser-sec {
  display: flex;
  flex-wrap: wrap;
  width: inherit;
  justify-content: flex-start;
}
.grid-projects-articles-events {
  margin: 20px 30px;
  max-width: 1170px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
  grid-gap: 20px;
}
.grid-projects-articles-events > #events {
  display: flex;
  flex-wrap: wrap;
  width: inherit;
  justify-content: flex-start;
}
.grid-projects-articles {
  display: flex;
  flex-wrap: wrap;
  width: inherit;
  justify-content: flex-start;
}
#fullwidth-teaser {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  background-color: #F2F2FB;
}
#fullwidth-teaser .section-wrapper {
  max-width: 1170px;
}
#fullwidth-teaser .section-wrapper .fullwidth-text {
  z-index: 5;
  max-width: 800px;
  color: #FFFFFF;
  background-color: #FFCC00;
  border-radius: 9px;
  padding: 20px;
  margin: 30px 30px;
  position: absolute;
  margin: 1.5em 30px;
  bottom: 0;
}
@media screen and (min-width: 800px) {
  #fullwidth-teaser .section-wrapper .fullwidth-text {
    width: max-content;
  }
}
@media screen and (max-width: 700px) {
  #fullwidth-teaser .section-wrapper .fullwidth-text {
    font-size: 1.1rem;
    margin: 20px 10px;
  }
}
#fullwidth-teaser .section-wrapper .fullwidth-text h1 {
  color: white;
  display: block;
  font-family: 'msb';
  font-size: 1.25rem;
}
@media screen and (max-width: 700px) {
  #fullwidth-teaser .section-wrapper .fullwidth-text h1 {
    font-size: 1.4rem;
  }
}
@media screen and (min-width: 700px) and (max-width: 800px) {
  #fullwidth-teaser .section-wrapper .fullwidth-text h1 {
    font-size: 1.4rem;
  }
}
#fullwidth-teaser .section-wrapper .fullwidth-text .teasertext {
  font-family: 'or';
  font-size: 0.91rem;
  margin-bottom: 0;
  color: #000033;
}
@media screen and (max-width: 700px) {
  #fullwidth-teaser .section-wrapper .fullwidth-text .teasertext {
    font-size: 1rem;
  }
}
@media screen and (min-width: 700px) and (max-width: 800px) {
  #fullwidth-teaser .section-wrapper .fullwidth-text .teasertext {
    font-size: 1rem;
  }
}
#fullwidth-teaser .section-wrapper .fullwidth-text .teasertext {
  font-size: 0.91rem;
  color: #000033;
  padding: 9px 0px 0px 0px;
}
@media screen and (max-width: 700px) {
  #fullwidth-teaser .section-wrapper .fullwidth-text .teasertext {
    font-size: 0.9rem;
  }
}
@media screen and (min-width: 700px) and (max-width: 800px) {
  #fullwidth-teaser .section-wrapper .fullwidth-text .teasertext {
    font-size: 0.9rem;
  }
}
#fullwidth-teaser .section-wrapper .fullwidth-text a h4 {
  font-family: 'msb';
  font-size: 1.25rem;
}
@media screen and (max-width: 700px) {
  #fullwidth-teaser .section-wrapper .fullwidth-text a h4 {
    font-size: 1.4rem;
  }
}
@media screen and (min-width: 700px) and (max-width: 800px) {
  #fullwidth-teaser .section-wrapper .fullwidth-text a h4 {
    font-size: 1.4rem;
  }
}
#fullwidth-teaser .section-wrapper .fullwidth {
  width: 100%;
  position: relative;
}
@media screen and (max-width: 700px) {
  #fullwidth-teaser .section-wrapper .fullwidth {
    position: relative;
    height: 100vh;
    width: 100vw;
  }
}
#fullwidth-teaser .section-wrapper .fullwidth img {
  width: 100%;
  height: auto;
}
@media screen and (max-width: 700px) {
  #fullwidth-teaser .section-wrapper .fullwidth img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
@media screen and (min-width: 1170px) {
  #fullwidth-teaser .section-wrapper .fullwidth img {
    border-radius: 9px;
  }
}
#gallery {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  background-color: #F2F2FB;
}
#gallery .gallery-image {
  display: flex;
  justify-content: center;
}
#gallery .section-wrapper {
  position: relative;
  max-width: 1020px;
  width: inherit;
}
#gallery .section-wrapper img {
  width: 100%;
  height: 700px;
  object-fit: contain;
  border-radius: 9px;
}
@media screen and (max-width: 800px) {
  #gallery .section-wrapper img {
    height: 500px;
  }
}
@media screen and (max-width: 700px) {
  #gallery .section-wrapper img {
    height: 350px;
  }
}
#gallery .gallery-text {
  display: none;
  max-width: 840px;
  margin: 30px 30px 40px 30px;
  background-color: #000033;
  border-radius: 9px;
  padding: 10px;
}
#gallery .gallery-text h1 {
  color: #FFFFFF;
}
.portaltype-startseite #gallery .gallery-text {
  background-color: #FFCC00;
}
.portaltype-startseite #gallery .gallery-text h1 {
  color: #000033;
}
@media screen and (min-width: 800px) {
  #gallery .gallery-text {
    width: max-content;
  }
}
@media screen and (max-width: 700px) {
  #gallery .gallery-text {
    font-size: 1.1rem;
    margin: 20px 10px;
  }
}
#gallery .gallery-text .teasertext {
  font-family: 'ml';
  font-size: 1.2rem;
  letter-spacing: 0.6px;
  line-height: 1.5;
  padding-bottom: 0;
  padding: 0;
}
@media screen and (max-width: 700px) {
  #gallery .gallery-text .teasertext {
    font-size: 1.2rem;
  }
}
@media screen and (min-width: 700px) and (max-width: 800px) {
  #gallery .gallery-text .teasertext {
    font-size: 1.2rem;
  }
}
.gallery-image img {
  background-color: #E1E1F7;
}
#faq-section {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  background-color: #F2F2FB;
}
#faq-section .section-wrapper {
  width: 100%;
  max-width: 1170px;
  margin: 20px 10px;
}
#faq_categories {
  width: 100%;
  max-width: 750px;
}
@media screen and (max-width: 700px) {
  #faq_categories {
    width: 100%;
  }
}
#faq_categories .faq_category {
  border-radius: 9px;
  background-color: #C2C2D6;
  padding: 10px;
  margin-bottom: 20px;
}
#faq_categories .faq_category h2 {
  margin: 10px;
  font-family: 'msb';
  font-size: 1.3rem;
  color: #ffffff;
}
@media screen and (max-width: 700px) {
  #faq_categories .faq_category h2 {
    margin: 10px;
  }
}
@media screen and (max-width: 700px) {
  #faq_categories .faq_category h2 {
    font-size: 1.3rem;
  }
}
@media screen and (min-width: 700px) and (max-width: 800px) {
  #faq_categories .faq_category h2 {
    font-size: 1.3rem;
  }
}
#faq_categories .faq_category .faq-entry .faq-question {
  font-family: 'msb';
  font-size: 0.91rem;
  letter-spacing: 0.6px;
  line-height: 1.2;
  color: #000033;
  margin: 0 30px / 2;
  padding: 10px 10px;
  text-align: left;
  border-top: solid;
  border-width: 2px;
  border-color: #000033;
  cursor: pointer;
}
@media screen and (max-width: 700px) {
  #faq_categories .faq_category .faq-entry .faq-question {
    font-size: 0.9rem;
  }
}
@media screen and (min-width: 700px) and (max-width: 800px) {
  #faq_categories .faq_category .faq-entry .faq-question {
    font-size: 0.9rem;
  }
}
#faq_categories .faq_category .faq-entry .faq-question.active,
#faq_categories .faq_category .faq-entry .faq-question:hover {
  color: #FFFFFF;
  background-color: #000033;
}
#faq_categories .faq_category .faq-entry .faq-answer {
  display: none;
  margin: 0 30px/2 0 30px/2;
  padding: 0 10px 30px 10px;
  background-color: #000033;
}
#faq_categories .faq_category .faq-entry .faq-answer p {
  font-family: 'ml';
  font-size: 0.91rem;
  letter-spacing: 0.6px;
  line-height: 1.2;
  color: #FFFFFF;
  font-style: normal;
}
@media screen and (max-width: 700px) {
  #faq_categories .faq_category .faq-entry .faq-answer p {
    font-size: 0.9rem;
  }
}
@media screen and (min-width: 700px) and (max-width: 800px) {
  #faq_categories .faq_category .faq-entry .faq-answer p {
    font-size: 0.9rem;
  }
}
#faq_categories .faq_category .faq-entry .faq-answer a {
  color: #FFFFFF;
}
#faq_categories .faq_category .faq-entry .faq-answer a:hover {
  color: #FF9999;
}
#faq_categories .faq_category .faq-entry a {
  margin-bottom: 10px;
}
.section-faq-beitrage #content {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  background-color: #F2F2FB;
}
.section-faq-beitrage #content .section-wrapper {
  max-width: 750px;
  width: 100%;
  margin: 20px 30px;
}
@media screen and (max-width: 700px) {
  .section-faq-beitrage #content .section-wrapper {
    margin: 20px 10px;
  }
}
.section-faq-beitrage #content .section-wrapper #faq_categories {
  width: 100%;
}
.section-faq-beitrage #content .section-wrapper #text {
  margin-bottom: 10px;
}
.section-faq-beitrage #content .section-wrapper #text .description {
  font-family: 'meb';
  font-size: 1.2rem;
  letter-spacing: 0.6px;
  line-height: 1.5;
  padding-bottom: 0;
}
@media screen and (max-width: 700px) {
  .section-faq-beitrage #content .section-wrapper #text .description {
    font-size: 1.2rem;
  }
}
@media screen and (min-width: 700px) and (max-width: 800px) {
  .section-faq-beitrage #content .section-wrapper #text .description {
    font-size: 1.2rem;
  }
}
#files {
  width: 100%;
  padding: 20px 0;
}
#files .teasertitle {
  order: initial;
  padding: 0 20px 10px 20px;
  font-family: 'msb';
  font-size: 1.25rem;
}
#files .file {
  margin: 0 20px;
}
.file {
  display: flex;
  flex-direction: row;
  grid-column: 1;
  max-width: 750px;
  overflow: hidden;
}
.file .file-icon,
.file .file-title,
.file .file-size {
  align-self: flex-start;
}
.file .file-title {
  padding-top: 6px;
  margin-right: 10px;
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.file .file-title a {
  font-size: 0.91rem;
  color: #000033;
}
@media screen and (max-width: 700px) {
  .file .file-title a {
    font-size: 0.9rem;
  }
}
@media screen and (min-width: 700px) and (max-width: 800px) {
  .file .file-title a {
    font-size: 0.9rem;
  }
}
.file .file-title a:hover {
  color: #FF9999;
}
.file .file-icon a {
  font-size: 1.5rem;
  color: #000033;
}
.file .file-icon a:hover {
  color: #FF9999;
}
.file img {
  width: 40px;
  height: 50px;
}
.file .discreet {
  font-size: 0.8rem;
  font-family: 'mr';
  font-size: 0.91rem;
  margin-bottom: 0;
  color: #000033;
}
.file audio,
.file video {
  margin-top: 1ex;
  margin-bottom: 1ex;
  width: 100%;
  border-radius: 9px;
}
#content_1 #files {
  min-width: 300px;
}
#footer {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
#footer .section-wrapper {
  display: flex;
  width: 100%;
  max-width: 1170px;
  padding-top: 50px;
  padding-bottom: 50px;
  margin: 0 10px;
}
@supports (display: grid) {
  #footer .section-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;
  }
  @media screen and (max-width: 700px) {
    #footer .section-wrapper {
      grid-template-columns: 1fr;
      grid-gap: 10px;
    }
  }
}
.footer-column {
  border-top-style: solid;
  border-bottom-style: solid;
  border-width: 2px;
  border-color: #000033;
  padding: 15px 0 15px 0;
  flex-basis: 33.3%;
}
@media screen and (max-width: 700px) {
  .footer-column {
    border-bottom-style: none;
    padding-top: 10px;
  }
}
.footer-column ul {
  margin-bottom: 0;
  margin-left: 0;
}
.footer-column li a,
.footer-column li a:visited,
.footer-column li a:active,
.footer-column li a:hover {
  font-family: 'mr';
  font-size: 1rem;
  line-height: 1.8;
  font-weight: normal;
  color: #000033;
  padding-bottom: 10px;
  text-decoration: none;
}
@media screen and (max-width: 800px) {
  .footer-column li a,
  .footer-column li a:visited,
  .footer-column li a:active,
  .footer-column li a:hover {
    font-size: 0.9rem;
  }
}
@media screen and (max-width: 700px) {
  .footer-column li a,
  .footer-column li a:visited,
  .footer-column li a:active,
  .footer-column li a:hover {
    font-size: 0.9rem;
    padding-bottom: 0;
  }
}
.swiper-container {
  width: 100%;
  height: inherit;
}
#visual-portal-wrapper .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20viewBox%3D%270%200%2027%2044%27%3E%3Cpath%20d%3D%27m%200.9889835%2C22%20v%200%20L%2022.988983%2C-5.0000001e-7%2027%2C4.0110165%208.6381365%2C22%2027%2C39.988983%2022.988983%2C44%20Z%27%20fill%3D%27%23ffcc00%27%2F%3E%3C%2Fsvg%3E");
  height: calc(100% - 150px);
  width: 45%;
  top: 0;
  left: 10px;
  background-position-x: left;
  background-position-y: calc(50% + 75px);
}
@media screen and (max-width: 700px) {
  #visual-portal-wrapper .swiper-button-prev {
    display: none;
  }
}
#visual-portal-wrapper .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20viewBox%3D%270%200%2027%2044%27%3E%3Cpath%20d%3D%27m%2027%2C22%20v%200%20L%205%2C44%200.98898305%2C39.988983%2019.350847%2C22%200.98898305%2C4.011017%205%2C0%20Z%27%20fill%3D%27%23FFCC00%27%2F%3E%3C%2Fsvg%3E");
  height: calc(100% - 150px);
  width: 50%;
  top: 0;
  right: 10px;
  background-position-x: right;
  background-position-y: calc(50% + 75px);
}
@media screen and (max-width: 700px) {
  #visual-portal-wrapper .swiper-button-next {
    display: none;
  }
}
#visual-portal-wrapper .swiper-container.disabled .swiper-button-next,
#visual-portal-wrapper .swiper-container.disabled .swiper-button-prev {
  display: none;
}
#visual-portal-wrapper .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background: #FFCC00;
  opacity: 0.55;
}
#visual-portal-wrapper .swiper-pagination-bullet-active {
  opacity: 1;
}
#content_1 .swiper-container,
#content_2 .swiper-container,
#intro .swiper-container,
#text .swiper-container {
  margin: 25px 0;
}
#content_1 .gallery-image,
#content_2 .gallery-image,
#intro .gallery-image,
#text .gallery-image {
  display: flex;
  justify-content: center;
}
#content_1 img,
#content_2 img,
#intro img,
#text img {
  width: 100%;
  height: 450px;
  object-fit: contain;
  border-radius: 9px;
}
@media screen and (max-width: 800px) {
  #content_1 img,
  #content_2 img,
  #intro img,
  #text img {
    height: 350px;
  }
}
@media screen and (max-width: 700px) {
  #content_1 img,
  #content_2 img,
  #intro img,
  #text img {
    height: 250px;
  }
}
#content_1 .gallery-text,
#content_2 .gallery-text,
#intro .gallery-text,
#text .gallery-text {
  display: none;
}
#map-navigation {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  background-color: #F2F2FB;
}
#map-navigation .section-wrapper {
  max-width: 1220px;
  width: 100%;
  margin: 20px 30px;
}
@media screen and (max-width: 700px) {
  #map-navigation .section-wrapper {
    margin: 20px 10px;
  }
}
.portaltype-startseite #map-navigation .section-wrapper {
  max-width: 1170px;
}
#map-navigation #list-map {
  max-width: 1170px;
}
#list-map {
  display: flex;
  margin: 0 auto;
  max-height: 620px;
}
@supports (display: grid) {
  #list-map {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-gap: 20px;
  }
  @media screen and (max-width: 700px) {
    #list-map {
      grid-template-columns: 1fr;
    }
  }
}
#list-map ul {
  list-style-type: none;
  margin-left: 0;
  margin-bottom: 0;
}
#list-map ul li {
  margin-left: 0;
}
#list-filters {
  border-radius: 9px;
  background-color: #C2C2D6;
  padding: 10px;
  margin-bottom: 20px;
  margin-right: 20px/2;
  height: 600px;
  flex-basis: 50%;
  overflow-y: auto;
}
@supports (display: grid) {
  #list-filters {
    margin-right: initial;
  }
}
#list-filters.show-filter {
  background-color: #FFCC00;
}
#list-filters.show-filter #filter {
  display: block;
}
#list-filters.show-filter #kitalist {
  display: none;
}
#list-filters .list-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  min-height: 70px;
}
#list-filters .h2 {
  margin: 30px/2 30px/2 30px 30px/2;
  font-family: 'msb';
  font-size: 1.3rem;
  color: #ffffff;
}
@media screen and (max-width: 700px) {
  #list-filters .h2 {
    font-size: 1.3rem;
  }
}
@media screen and (min-width: 700px) and (max-width: 800px) {
  #list-filters .h2 {
    font-size: 1.3rem;
  }
}
#list-filters .h3.toggle-filter {
  margin: 30px/2 30px/2 30px 30px/2;
  font-family: 'msb';
  font-size: 0.91rem;
  letter-spacing: 0.6px;
  line-height: 1.2;
  color: #000033;
  color: #ffffff;
}
@media screen and (max-width: 700px) {
  #list-filters .h3.toggle-filter {
    font-size: 0.9rem;
  }
}
@media screen and (min-width: 700px) and (max-width: 800px) {
  #list-filters .h3.toggle-filter {
    font-size: 0.9rem;
  }
}
#list-filters .h3.toggle-filter .icon-ebk-filter {
  font-size: 1.3rem;
}
@media screen and (max-width: 700px) {
  #list-filters .h3.toggle-filter .icon-ebk-filter {
    font-size: 1.3rem;
  }
}
@media screen and (min-width: 700px) and (max-width: 800px) {
  #list-filters .h3.toggle-filter .icon-ebk-filter {
    font-size: 1.3rem;
  }
}
#list-filters .h3.toggle-filter .icon-ebk-filter:hover,
#list-filters .h3.toggle-filter .icon-ebk-filter.active {
  color: #FFCC00;
}
#filter {
  display: none;
}
#filter .filter-header {
  display: flex;
  justify-content: space-between;
  position: relative;
}
#filter .filter-header .h2 {
  border-radius: 5px;
  background-color: #C2C2D6;
  padding: 0.3rem;
  line-height: 1.9rem;
  cursor: default;
}
#filter .filter-header .h3.toggle-filter {
  font-size: 2.2rem;
  color: #000033;
  cursor: default;
}
@media screen and (max-width: 700px) {
  #filter .filter-header .h3.toggle-filter {
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 700px) and (max-width: 800px) {
  #filter .filter-header .h3.toggle-filter {
    font-size: 1.5rem;
  }
}
.filter-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 30px;
  padding: 0 5px;
}
@media screen and (max-width: 700px) {
  .filter-container {
    grid-template-columns: 1fr;
    padding: 0 30px;
  }
}
.filter-container .button {
  font-size: 1.3rem;
  margin: 10px;
  border-radius: 5px;
  border-style: none;
  padding: 0.3rem;
  background-color: #ffffff;
}
@media screen and (max-width: 700px) {
  .filter-container .button {
    font-size: 1.3rem;
  }
}
@media screen and (min-width: 700px) and (max-width: 800px) {
  .filter-container .button {
    font-size: 1.3rem;
  }
}
.filter-container .button:hover {
  background-color: #dddddd;
}
#kitamap {
  border-radius: 9px;
  height: 620px;
  z-index: 3;
  margin-left: 20px/2;
  flex-basis: 50%;
}
@supports (display: grid) {
  #kitamap {
    margin-left: initial;
  }
}
@media screen and (max-width: 700px) {
  #kitamap {
    display: none;
  }
}
#kitalist {
  height: 100%;
  display: flex;
  flex-direction: column;
}
#kitalist ul {
  overflow-y: auto;
}
#kitalist li .listentry {
  font-family: 'msb';
  font-size: 0.91rem;
  letter-spacing: 0.6px;
  line-height: 1.2;
  color: #000033;
  margin: 0 30px / 2;
  padding: 10px;
  text-align: left;
  border-top: solid;
  border-width: 2px;
  border-color: #000033;
}
@media screen and (max-width: 700px) {
  #kitalist li .listentry {
    font-size: 0.9rem;
  }
}
@media screen and (min-width: 700px) and (max-width: 800px) {
  #kitalist li .listentry {
    font-size: 0.9rem;
  }
}
#kitalist li .listdetail {
  display: none;
  margin: 0 30px/2 0 30px/2;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  font-family: 'ml';
  font-size: 0.91rem;
  letter-spacing: 0.6px;
  line-height: 1.2;
  color: #FFFFFF;
  font-style: normal;
}
@media screen and (max-width: 700px) {
  #kitalist li .listdetail {
    font-size: 0.9rem;
  }
}
@media screen and (min-width: 700px) and (max-width: 800px) {
  #kitalist li .listdetail {
    font-size: 0.9rem;
  }
}
#kitalist li .listdetail p {
  font-family: 'ml';
  font-size: 0.91rem;
  letter-spacing: 0.6px;
  line-height: 1.2;
  color: #FFFFFF;
}
@media screen and (max-width: 700px) {
  #kitalist li .listdetail p {
    font-size: 0.9rem;
  }
}
@media screen and (min-width: 700px) and (max-width: 800px) {
  #kitalist li .listdetail p {
    font-size: 0.9rem;
  }
}
#kitalist li a {
  font-family: 'msb';
  font-size: 0.91rem;
  letter-spacing: 0.6px;
  line-height: 1.2;
  color: #000033;
  margin-top: 10px;
}
@media screen and (max-width: 700px) {
  #kitalist li a {
    font-size: 0.9rem;
  }
}
@media screen and (min-width: 700px) and (max-width: 800px) {
  #kitalist li a {
    font-size: 0.9rem;
  }
}
#kitalist li:hover a,
#kitalist li.active a,
#kitalist li:hover .listentry,
#kitalist li.active .listentry,
#kitalist li:hover .listdetail,
#kitalist li.active .listdetail,
#kitalist li:hover .listdetail p,
#kitalist li.active .listdetail p {
  background-color: #000033;
  color: #ffffff;
}
button.active {
  color: red;
}
#facility_map {
  border-radius: 9px;
  height: 320px;
  margin-left: 5px;
  margin-right: 5px;
  z-index: 3;
}
.leaflet-container .leaflet-control-attribution,
.leaflet-container .leaflet-control-attribution a,
.leaflet-container .leaflet-control-scale {
  font-size: 85%;
}
input:focus {
  outline: none;
  box-shadow: inset 0 0 3px #333333;
}
#menu.scroll .suchschlitz {
  height: 35px;
}
#menu.scroll .suchschlitz input {
  margin-top: -20px;
  opacity: 0;
}
#menu.scroll .menu.active .suchschlitz input,
#menu.scroll .menu:hover .suchschlitz input {
  opacity: 1;
  margin-top: 0;
}
.suchschlitz {
  width: 100%;
  grid-row-start: 1;
  align-self: baseline;
  padding: 8px 0;
  height: 70px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.suchschlitz form {
  background-color: transparent;
}
@media screen and (max-width: 800px) {
  .suchschlitz form {
    display: none;
  }
}
@media screen and (max-width: 800px) {
  .suchschlitz {
    height: 54px;
  }
}
.suchschlitz input {
  font-family: 'Mitr';
  font-size: 1rem;
  border: transparent;
  background-color: #F2F2FB;
  border-radius: 4px 0 0 4px;
  opacity: 1;
  transition: margin-top 250ms ease, opacity 250ms ease;
  float: left;
  padding: 4px;
}
.suchschlitz input[type="text"] {
  width: calc(100% - 40px);
  box-sizing: border-box;
  padding-left: 8px;
}
.suchschlitz input[type="submit"] {
  width: 40px;
  color: transparent;
  background-color: #C2C2D6;
  background-image: url('svg/lupe.svg');
  background-size: contain;
  background-position-x: center;
  background-repeat: no-repeat;
  border-radius: 0 4px 4px 0px;
  box-sizing: border-box;
}
.suchschlitz input[type="submit"]:hover {
  background-image: url('svg/lupe_active.svg');
}
.search-form {
  display: flex;
  background-color: #C2C2D6;
  gap: 10px;
  border-radius: 9px;
  padding: 10px;
  font-family: 'Mitr';
  font-size: 1.25rem;
  margin-bottom: 10px;
}
.search-form .input-container {
  flex-grow: 2;
}
.search-form input {
  font-family: 'Mitr';
  font-size: 1.25rem;
  border: transparent;
  border-radius: 9px;
  padding: 10px;
}
.search-form input[type="text"] {
  width: 100%;
  box-sizing: border-box;
}
.search-form input[type="submit"] {
  color: transparent;
  background-color: #000033;
  background-image: url('svg/lupe.svg');
  width: 52px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.search-form input[type="submit"]:hover {
  text-decoration: underline;
  background-image: url('svg/lupe_active.svg');
}
.results-container {
  display: flex;
  background-color: #C2C2D6;
  border-radius: 9px;
  padding: 10px;
  flex-direction: column;
  margin-bottom: 10px;
}
.results-container > div {
  border-bottom: #000033 1px solid;
  padding: 10px 0;
}
.results-container > div:last-child {
  border-bottom: initial;
}
.result-title a {
  font-family: 'Mitr';
  font-size: 1.2rem;
  color: #000033;
}
.result-type,
.results-heading,
.results-description {
  font-family: 'Mitr';
  font-size: 1rem;
  color: #000033;
}
.results-heading-title {
  font-size: 1.2rem;
}
.result-type {
  margin-top: 1ex;
}
.result-type a {
  font-size: 0.91rem;
  color: #000033;
}
.result-type a:hover,
.result-type a:active,
.result-type a:visited {
  color: #000033;
}
.result-type a:hover {
  text-decoration: underline;
}
.result-description {
  font-family: 'Lato';
  font-size: 1rem;
  color: #000033;
}
#content .pagination {
  display: inline-block;
}
#content .pagination ul {
  display: flex;
  gap: 10px;
  list-style-type: none;
  flex-wrap: wrap;
  margin: 0;
  background-color: #C2C2D6;
  border-radius: 9px;
  padding: 10px;
  max-width: 630px;
}
#content .pagination ul li {
  font-family: 'Mitr';
  font-size: 1.25rem;
  padding: 10px;
  background-color: #000033;
  color: #fff;
  border-radius: 9px;
  margin: 0;
  min-width: 28px;
  text-align: center;
}
#content .pagination ul li.active {
  background-color: #FFCC00;
  color: #000033;
}
#content .pagination ul li a {
  color: #fff;
  text-decoration: none;
  font-family: 'Mitr';
  font-size: 1.25rem;
}
#content .pagination ul li a:hover {
  text-decoration: underline;
}
#content .pagination ul li .label {
  display: none;
}
#content .pagination ul li.previous,
#content .pagination ul li.next {
  display: none;
}
#search {
  grid-column-start: 1;
  grid-row-start: 1;
  grid-row-end: 3;
  overflow: hidden;
  align-self: start;
  width: 100%;
  max-width: 750px;
  padding-bottom: 30px;
}
@media screen and (max-width: 700px) {
  #search {
    grid-row-start: 1;
    grid-row-end: 1;
  }
}
.w-100 {
  width: 100%;
}
.suche-mobil {
  display: none;
}
@media screen and (max-width: 800px) {
  .suche-mobil {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
.lupe {
  background-image: url('svg/lupe.svg');
  height: 50px;
  width: 50px;
  background-repeat: no-repeat;
  background-size: cover;
  transition: height 250ms ease, width 250ms ease;
}
.scroll .lupe {
  height: 0;
  width: 0;
}
#text a.cta-button {
  font-family: 'Mitr';
}
a.cta-button {
  display: inline-block;
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: 1.2rem;
  font-family: 'Mitr';
  border-radius: 9px;
  padding: 0.5rem;
  text-decoration: none;
}
a.cta-button.red {
  background-color: #DE1224;
  color: white;
}
a.cta-button.yellow {
  background-color: #FFCC00;
  color: #000033;
}
a.cta-button:hover {
  text-decoration: underline;
}
#news {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  background-color: #F2F2FB;
}
#news .section-wrapper {
  max-width: 1220px;
  width: 100%;
  margin-top: 2.4rem;
}
.portaltype-startseite #news .section-wrapper {
  max-width: 1170px;
}
#news #news-inner,
#news #news-inner-noannouncements {
  max-width: 1170px;
}
#news-inner,
#news-inner-noannouncements {
  display: flex;
  margin: 0 auto;
}
@supports (display: grid) {
  #news-inner,
  #news-inner-noannouncements {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: auto auto;
    grid-gap: 20px;
  }
  @media screen and (max-width: 700px) {
    #news-inner,
    #news-inner-noannouncements {
      grid-template-columns: 1fr;
      grid-template-rows: 2fr;
    }
  }
}
@supports (display: grid) {
  #news-inner-noannouncements {
    display: grid;
    grid-template-columns: 3fr;
    grid-template-rows: auto;
  }
}
#new-contents,
#new-announcements {
  border-radius: 9px;
  position: relative;
  background-color: #C2C2D6;
  padding: 1.2rem;
  padding-bottom: 20px;
  margin-bottom: 20px;
  margin-right: 20px/2;
  flex-basis: 66%;
}
@supports (display: grid) {
  #new-contents,
  #new-announcements {
    margin-right: initial;
  }
}
#new-contents hr,
#new-announcements hr {
  border-style: none;
  border-top-style: solid;
}
#new-contents p,
#new-announcements p {
  margin-bottom: 0.75rem;
}
#new-contents .h2,
#new-announcements .h2 {
  margin: 30px/2 30px/2 30px 30px/2;
  font-family: 'msb';
  font-size: 1.3rem;
  color: #ffffff;
}
@media screen and (max-width: 700px) {
  #new-contents .h2,
  #new-announcements .h2 {
    font-size: 1.3rem;
  }
}
@media screen and (min-width: 700px) and (max-width: 800px) {
  #new-contents .h2,
  #new-announcements .h2 {
    font-size: 1.3rem;
  }
}
#new-contents .box-type,
#new-announcements .box-type {
  padding-left: 0;
  margin-bottom: 0.5rem;
}
#new-contents h4,
#new-announcements h4 {
  font-family: 'msb';
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}
#new-contents a.more-link,
#new-announcements a.more-link {
  display: block;
  right: 20px;
  position: absolute;
  bottom: 10px;
  color: black;
  font-weight: bold;
  font-family: 'Lato';
  font-size: 15px;
}
#new-announcements {
  background-color: #FFCC00;
  flex-basis: 33%;
}
#new-announcements hr {
  border-top-style: dashed;
}
body.portaltype-easyform #stage {
  min-height: 50px;
}
body.portaltype-easyform #content {
  background-color: #F2F2FB;
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
body.portaltype-easyform #content div.form {
  padding: 20px;
}
body.portaltype-easyform #content div.form .portalMessage {
  margin-left: 0;
}
body.portaltype-easyform #content div.form form {
  font-family: 'Mitr';
  background-color: transparent;
}
body.portaltype-easyform #content div.form form label {
  font-size: 1rem;
}
body.portaltype-easyform #content div.form form label .formHelp {
  display: block;
  color: black;
  font-family: 'Lato';
  font-size: 0.91rem;
}
body.portaltype-easyform #content div.form form div.error {
  font-size: 1rem;
}
body.portaltype-easyform #content div.form form div.field span.option {
  display: block;
  font-family: 'Lato';
  height: 3ex;
}
body.portaltype-easyform #content div.form form div.field span.option:last-child {
  margin-bottom: 2ex;
}
body.portaltype-easyform #content div.form form input,
body.portaltype-easyform #content div.form form textarea,
body.portaltype-easyform #content div.form form select {
  font-family: 'Lato';
  background-color: #f2f2fb;
  width: 30em;
  padding-left: 15px;
  padding-top: 7px;
  padding-bottom: 7px;
  margin-bottom: 12px;
  border: none;
  border-bottom: 1px solid #aaa;
}
body.portaltype-easyform #content div.form form input:focus,
body.portaltype-easyform #content div.form form textarea:focus,
body.portaltype-easyform #content div.form form select:focus {
  box-shadow: none;
}
body.portaltype-easyform #content div.form form input.required,
body.portaltype-easyform #content div.form form textarea.required,
body.portaltype-easyform #content div.form form select.required {
  border-left: 2px solid red;
}
body.portaltype-easyform #content div.form form input[type=radio],
body.portaltype-easyform #content div.form form input[type=checkbox] {
  margin-top: 15px;
  margin-bottom: 20px;
  width: auto;
}
body.portaltype-easyform #content div.form form input[type=submit],
body.portaltype-easyform #content div.form form input[type=button] {
  display: inline-block;
  margin-bottom: 10px;
  margin-top: 10px;
  width: 50%;
  font-size: 1.2rem;
  font-family: 'Mitr';
  border-radius: 9px;
  padding: 0.5rem;
  text-decoration: none;
  background-color: #DE1224;
  color: white;
}
body.portaltype-easyform #content div.form form input[type=submit]:hover,
body.portaltype-easyform #content div.form form input[type=button]:hover {
  text-decoration: underline;
}
body.portaltype-easyform #content div.form form #formPrologue,
body.portaltype-easyform #content div.form form #formEpilogue {
  margin-top: 20px;
  margin-bottom: 20px;
}
/*# sourceMappingURL=++theme++eb-kita-theme/frontend/less/main-compiled.less.map */